











































































import { computed, defineComponent, nextTick, PropOptions } from "vue";
import { YandexMapAddressItemInterface } from "@/models/yandex-map/YandexMapAddressItem.interface";
import ymaps, { ymaps as yMapsTypes } from "ymaps";
import { YandexMapSuggestionItemInterface } from "@/models/yandex-map/YandexMapSuggestionItem.interface";
import { YandexMapService } from "@/models/yandex-map/YandexMap.service";
import YandexMapSuggestionInput from "@/components/yandex-map-suggestion/YandexMapSuggestionInput.vue";
import { useUserStore } from "@/store/useUserStore";

export default defineComponent({
  name: "YandexMapDialog",
  components: { YandexMapSuggestionInput },
  props: {
    value: {
      type: Object,
      required: true
    } as PropOptions<YandexMapAddressItemInterface>,
    label: {
      type: String,
      required: true
    } as PropOptions<string>,
    error: {
      type: Boolean,
      default: false
    } as PropOptions<boolean>,
    errorMessages: {
      type: [String, Array],
      default: ""
    } as PropOptions<string | string[]>,
    addressesPrompt: {
      type: Array,
      default: () => [] as YandexMapAddressItemInterface[]
    } as PropOptions<YandexMapAddressItemInterface[]>,
    mapLabel: {
      type: String,
      default: ""
    } as PropOptions<string>,
    showDialog: {
      type: Boolean,
      default: false
    } as PropOptions<boolean>
  },
  setup() {
    const userStore = useUserStore();

    const ownerCountry = computed<string>(() => userStore.ownerCountry);

    return { ownerCountry };
  },
  data() {
    return {
      ymaps: null as typeof yMapsTypes | null,
      map: null as yMapsTypes.Map | null,
      placemark: null as yMapsTypes.Placemark | null,
      confirmDialog: false,
      previousAddress: null as YandexMapAddressItemInterface | null,
      currentAddress: null as YandexMapAddressItemInterface | null,
      mapInitialValue: null as string | null
    };
  },
  watch: {
    async showDialog(value) {
      this.placemark = null;

      if (value) {
        this.previousAddress = JSON.parse(JSON.stringify(this.value));

        await nextTick();
        this.initMap();
      }
    }
  },
  mounted(): void {
    ymaps
      .load(
        "https://api-maps.yandex.ru/2.1/?apikey=736f8e29-4276-4cda-b223-d0c31c283d76&lang=ru-RU"
      )
      .then(map => {
        this.ymaps = map;
      });
  },
  methods: {
    onClose(): void {
      if (this.mapInitialValue !== this.value.value) {
        this.confirmDialog = true;
      } else {
        this.onAbort();
      }
    },
    onAbort(): void {
      if (this.previousAddress) {
        this.$emit("input", this.previousAddress);
      }
      this.confirmDialog = false;
      this.$emit("close");
    },
    onSave(): void {
      this.previousAddress = this.currentAddress;
      this.$emit("close");
    },
    getLocation(countryCode: string): { coords: number[]; zoom: number } {
      switch (countryCode) {
        case "RUS":
          return { coords: [61.698883, 99.504494], zoom: 3 };
        case "KAZ":
          return { coords: [51.143964, 71.435819], zoom: 4.5 };
        default:
          return { coords: [61.698883, 99.504494], zoom: 3 };
      }
    },
    initMap(): void {
      this.mapInitialValue = this.value.value;

      if (this.ymaps) {
        const location: { coords: number[]; zoom: number } = this.getLocation(
          this.ownerCountry
        );

        this.map = new this.ymaps.Map(
          "map",
          {
            center: location.coords,
            zoom: location.zoom,
            controls: ["zoomControl"]
          },
          {
            suppressMapOpenBlock: true
          }
        );

        let clickTimeout: ReturnType<typeof setTimeout> | null = null;
        const doubleClickDelay = 300; // Время для определение двойного клика

        this.map.events.add("click", (e: yMapsTypes.Event) => {
          if (clickTimeout) {
            clearTimeout(clickTimeout);
            clickTimeout = null;
            // Обработка двойного клика (масшбат карты)
            if (this.map) this.map.setZoom(this.map.getZoom() + 1);
          } else {
            clickTimeout = setTimeout(() => {
              clickTimeout = null;
              // Обработка одинарного клика (вставить плейсмарк)
              const coords: [number, number] = e.get("coords");

              const coordsStr = coords.join(", ");
              this.updatePlacemark(coordsStr, false);

              const suggestionItem = {
                value: coordsStr,
                displayName: coordsStr
              };

              this.onInput(suggestionItem, false);
            }, doubleClickDelay);
          }
        });

        if (this.value) {
          this.updatePlacemark(this.value.value);
        }
      }
    },
    updatePlacemark(address: string, zoomTo = true): void {
      if (this.ymaps && address) {
        this.ymaps.geocode(address).then((res: any) => {
          const firstGeoObject: any = res.geoObjects.get(0);
          const coords = firstGeoObject.geometry.getCoordinates();

          if (!this.ymaps || !this.map) return;

          this.map.geoObjects.removeAll();
          this.placemark = new this.ymaps.Placemark(
            coords,
            {
              hintContent: "Выберите адрес"
            },
            {
              draggable: true,
              preset: "islands#redIcon"
            }
          );
          this.map.geoObjects.add(this.placemark);

          this.placemark.events.add("dragend", () => {
            if (this.placemark && this.placemark.geometry) {
              const coords = this.placemark.geometry.getCoordinates();
              const coordsStr = coords ? coords.join(",") : null;

              if (coordsStr == null) return;

              const suggestionItem = {
                value: coordsStr,
                displayName: coordsStr
              };

              this.onInput(suggestionItem, false);
            }
          });

          if (zoomTo) {
            const bounds = firstGeoObject.properties.get("boundedBy");

            this.map.setBounds(bounds, { checkZoomRange: true }).then(() => {
              if (this.map && this.map.getZoom() > 17) {
                this.map.setZoom(17);
              }
            });
          }
        });
      }
    },
    async onInput(
      item: YandexMapSuggestionItemInterface,
      updatePlaceMark = true
    ): Promise<void> {
      const searchedItems = await YandexMapService.geocode(item.value);

      if (searchedItems.length === 0) {
        return;
      }

      this.$emit("input", searchedItems[0]);

      await nextTick();

      if (updatePlaceMark) {
        this.updatePlacemark(searchedItems[0].value);
      }
    }
  }
});
