





























































































import { LoadingAddressObjectInterface } from "@/models/order/AddressObject.interface";
import AddressItemFtl from "@/components/order/addresses/AddressItemFtl.vue";
import { required } from "@vuelidate/validators";
import { computed, defineComponent, PropType, nextTick, inject } from "vue";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "LoadingAddressObj",
  components: {
    AddressItemFtl
  },
  props: {
    loadingAddressObj: {
      type: Object as PropType<LoadingAddressObjectInterface>,
      required: true
    },
    idx: {
      type: Number as PropType<number>,
      required: true
    },
    showRemove: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    showCount: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ["remove"],
  setup(props) {
    const rules = computed(() => {
      return {
        unloadingAddresses: {
          required
        }
      };
    });

    const v$ = useVuelidate(rules, props.loadingAddressObj);
    const hasEdm = inject<boolean>("hasEdm");

    const addUnloadingAddress = async () => {
      props.loadingAddressObj.addUnloadingAddress();

      await nextTick();
      v$.value.$touch();
    };

    return { v$, addUnloadingAddress, hasEdm };
  }
});
